import { palette } from '../../../uibook/src/colors';

export const DEFAULT_THEME_PALETTE = palette;

/**
 * This is the base theme of the app, which also sets the defaults for each component. Any component
 * which is "themeable" should have the default set here. The default should be the value used for
 * the Raylo theme.
 */
export const DEFAULT_THEME_VARIABLES = {
  primary: palette.blue[500],
  header: palette.blue[500],
  'nav-divider': palette.blue[400],
  'nav-link-hover': palette.blue[400],
  'breadcrumb-link-hover': palette.blue[200],
  'breadcrumb-link-disabled': palette.pink[300],
  'banner-button-with-icon': palette.blue[500],
  'icon-primary': palette.pink[500],
  'icon-bullet': palette.blue[500],
  'checkbox-active': palette.blue[500],
  'checkbox-disabled': palette.blue[200],
  'radio-button-box-active': palette.blue[500],
  'progress-bar': palette.pink[300],
  'alert-fill': palette.blue[100],
  'alert-border': palette.blue[200],
  'section-info-fill': palette.pink[200],
  'legacy-modal-close-fill': palette.pink[300],
  'how-it-works-fill': palette.blue[100],
  'how-it-works-number': palette.blue[200],
  'how-it-works-icon': palette.blue[500],
  'pdp-header-fill': palette.blue[500],
  'header-benefits-fill': palette.pink[300],
  'header-benefits-fill-md': palette.blue[500],
  'header-benefits-icon-md': palette.pink[300],
  'header-benefits-box-fill': palette.pink[300],
  'header-benefits-title': palette.charcoal[500],
  'checkout-summary-fill': palette.blue[500],
  'checkout-summary-scrollbar-thumb': palette.pink[300],
  'checkout-summary-scrollbar-track': palette.blue[500],
  'trustpilot-bullets-icon': palette.pink[300],
  'company-search-button': palette.blue[400],
  'company-search-button-hover': palette.blue[500],
  'company-search-select-focus': palette.blue[300],
  'company-search-select-active': palette.blue[200],
  'checkout-header-fill': palette.blue[500],
};
